import React, { useState } from "react"
import styles from "./videoSection.module.scss"

const VideoSection = ({ title, src, poster }) => {
  const [isPosterClicked, setIsPosterClicked] = useState(false)
  const handleClick = (e) => {
    if (!isPosterClicked) {
      e.preventDefault()
      e.target.play()
      setIsPosterClicked(true)
    }
  }
  return (
    <div className={styles.wrapper}>
      <div className={styles.polygon} />
      <h2>{title}</h2>
      <video
        src={src}
        poster={poster}
        alt="Video"
        onClick={handleClick}
        controls={isPosterClicked}
      />
    </div>
  )
}

export default VideoSection
