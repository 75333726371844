import React from "react"
import YouTube from "react-youtube"
import Button from "../button"
import { openModal } from "../../services/modal"
import styles from "./callToActionBlock.module.scss"

const CallToActionBlock = ({ title, subtitle, videoId }) => {
  return (
    <div className={styles.wrapper}>
      <h2>{title}</h2>
      <p className={styles.subtitle}>{subtitle}</p>
      {videoId && (
        <YouTube
          videoId={videoId}
          opts={{
            height: "477",
            width: "848",
          }}
        />
      )}
      <Button classStyle={styles.primaryButton} onClick={openModal}>
        BOOK A MEETING
      </Button>
      <p className={styles.text}>
        or{" "}
        <button className={styles.secondaryButton} onClick={openModal}>
          Start Free Trial
        </button>
      </p>
    </div>
  )
}

export default CallToActionBlock
