import React, { useMemo } from "react"
import GatsbyImage from "gatsby-image"
import styles from "./feature.module.scss"

const Feature = ({ name, title, description, image, leftCut, rightCut }) => {
  return (
    <div
      className={`${styles.wrapper} ${
        leftCut ? styles.leftCut : rightCut ? styles.rightCut : ""
      }`}
    >
      <p className={styles.hightlighted}>{name}</p>
      <h2>{title}</h2>
      <p className={styles.description}>{description}</p>
      <GatsbyImage
        fluid={image}
        className={styles.imageWrapper}
        imgStyle={{
          objectFit: "contain",
        }}
      />
    </div>
  )
}

export default Feature
